<template>
  <div class="app-card">
    <a-form-model
      ref="ruleForm"
      :model="form"
      :rules="rules"
      :label-col="labelCol"
      :wrapper-col="wrapperCol"
    >
      <a-form-model-item label="门店图片">
        <a-row>
          <a-col style="display: contents;" :span="8" v-if="['create', 'edit'].includes(formMode)">
            <a-upload-dragger
              :multiple="true"
              list-type="picture-card"
              :file-list="files"
              :showUploadList="true"
              :remove="handleRemoveImage"
              @preview="handlePreview"
              :beforeUpload="beforeUploadImage"
              @change="changeUploadImage"
            >
              <p class="ant-upload-drag-icon">
                <a-icon type="inbox" />
              </p>
              <p class="ant-upload-text">
                图片上传
              </p>
              <p class="ant-upload-hint">
                仅支持上传JPG或PNG图片
              </p>
            </a-upload-dragger>
          </a-col>
          <a-col :span="16" v-if="beforeFiles && beforeFiles.length > 0">
            <a-upload
              list-type="picture-card"
              :file-list="beforeFiles"
              @preview="handlePreview"
              :remove="handleRemoveBeforeImage"
              :beforeUpload="() => false"
            >
            </a-upload>
          </a-col>
        </a-row>
      </a-form-model-item>
      <a-form-model-item label="商家名称" prop="name">
        <a-input v-model="form.name" />
      </a-form-model-item>
      <a-form-model-item label="经营类型" prop="businessType">
        <a-select v-model="form.businessType">
              <a-select-option v-for="it in businessTypeList" :key="it.key">{{it.value}}</a-select-option>
            </a-select>
      </a-form-model-item>
      <a-form-model-item label="商家类型" prop="typeName">
        <a-select v-model="form.typeName">
              <a-select-option v-for="it in typeNameList" :key="it.key">{{it.value}}</a-select-option>
            </a-select>
      </a-form-model-item>
      <a-form-model-item label="服务描述" prop="subTitle">
        <a-input v-model="form.subTitle" />
      </a-form-model-item>
      <a-form-model-item label="是否是主商户">
        <a-switch checked-children="是" un-checked-children="否" v-model="form.isMain" />
      </a-form-model-item>
      <a-form-model-item label="归属门店" v-if="!form.isMain" prop="belong">
        <a-select
          show-search
          v-model="form.belong"
          placeholder="input search text"
          style="width: 200px"
          :default-active-first-option="false"
          :show-arrow="false"
          :filter-option="false"
          :not-found-content="fetchingBelong ? undefined : null"
          @search="handleSearchBelong"
        >
          <a-spin v-if="fetchingBelong" slot="notFoundContent" size="small" />
          <a-select-option v-for="d in belongList" :key="d.uuid">
            {{ d.name }}
          </a-select-option>
        </a-select>
      </a-form-model-item>
      <a-form-model-item label="营业时间" prop="openingHours">
        <a-input v-model="form.openingHours" />
      </a-form-model-item>
      <a-form-model-item label="服务电话" prop="servicePhone">
        <a-input v-model="form.servicePhone" />
      </a-form-model-item>
      <a-form-model-item label="服务质量评分(百分制)">
        <a-input-number :min="0" :max="100" :step="0.5" v-model="form.score" />
      </a-form-model-item>
      <a-form-model-item
        v-for="(score, index) in form.insScores"
        :key="score.key"
        v-bind="index === 0 ? formItemLayout : formItemLayoutWithOutLabel"
        :label="index === 0 ? '保险公司服务评分' : ''"
        :prop="'score.' + index + '.value'"
      >
        <a-input-group compact>
          <a-select style="width: 150px;" v-model="score.name">
            <a-select-option v-for="n in insuranceBaseList" :key="n" :value="n">
              {{n}}
            </a-select-option>
          </a-select>
          <a-rate v-model="score.value" allow-half style="padding-right:20px" />
          <a-icon
          v-if="form.insScores.length > 1"
          class="dynamic-delete-button"
          type="minus-circle-o"
          :disabled="form.insScores.length === 1"
          @click="removeInsScores(score)"
        />
        </a-input-group>
        
      </a-form-model-item>
      <a-form-model-item v-bind="formItemLayoutWithOutLabel">
        <a-button type="dashed" style="width: 60%" @click="addInsSorces">
          <a-icon type="plus" /> 添加保险公司服务评分
        </a-button>
      </a-form-model-item>
      
      <a-form-model-item label="是否可预约">
        <a-switch checked-children="是" un-checked-children="否" v-model="form.canAppointment" />
      </a-form-model-item>
      <a-form-model-item label="当前订单数">
        <a-input-number :min="0" :step="1" v-model="form.orderCount" />
      </a-form-model-item>
      <a-form-model-item label="地址" prop="address">
        <a-input-search v-model="form.address" enter-button="搜索坐标" @search="searchAddressPosition" />
      </a-form-model-item>
      <a-form-model-item label="坐标" prop="addressPosition">
        <baidu-map class="map" center="昆明" @ready="mapReaderhandler">
          <bm-navigation anchor="BMAP_ANCHOR_TOP_RIGHT"></bm-navigation>
          <bm-marker v-if="form.addressPosition" :position="form.addressPosition" :dragging="true"></bm-marker>
        </baidu-map>
      </a-form-model-item>
      <a-form-model-item label="商家介绍" style="padding-bottom:130px">
        <quill-editor
          style="height:440px;"
          v-model="form.content"
          :options="{placeholder: '请填写内容', ...editerOptions}"
        />
      </a-form-model-item>
      <a-form-model-item :wrapper-col="{ span: 14, offset: 4 }">
        <a-button type="primary" @click="onSubmit">
          提交
        </a-button>
      </a-form-model-item>
    </a-form-model>
    <a-modal :visible="previewVisible" :footer="null" @cancel="handleCancelPreview">
      <div v-viewer>
        <img alt="example" style="width: 100%" :src="previewImage" />
      </div>
    </a-modal>
    <a-modal
      :width="200"
      :visible="modalLoading"
      :closable="false"
      :maskClosable="false"
      :footer="null"
    >
      <a-row>
        <a-col style="text-align: center;">
          <a-icon type="loading" :style="{ fontSize: '36px', color: '#1890ff' }" />
        </a-col>
        <a-col style="text-align: center;color:#1890ff;padding-top:10px;">
          {{modalLoadingMessage}}
        </a-col>
      </a-row>
      
    </a-modal>
  </div>
</template>

<script>
import { BusinessShop, Utils } from "@/api";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

export default {
  name: 'BusinessShopAddAndEdit',
  mounted () {
    this.GetInsuranceBaseList()
    if (this.$route.params && this.$route.params.uuid) {
      this.formMode = 'edit'
      this.getDetail(this.$route.params.uuid)
    }
  },
  data () {
    return {
      modalLoading: false,
      modalLoadingMessage: '正在处理数据...',
      labelCol: { span: 4 },
      wrapperCol: { span: 14 },
      formItemLayout: {
        labelCol: {
          xs: { span: 24 },
          sm: { span: 4 },
        },
        wrapperCol: {
          xs: { span: 24 },
          sm: { span: 20 },
        },
      },
      formItemLayoutWithOutLabel: {
        wrapperCol: {
          xs: { span: 24, offset: 0 },
          sm: { span: 20, offset: 4 },
        },
      },
      typeNameList: [
        {key:'连锁店', value: '连锁店'},
        {key:'旗舰店', value: '旗舰店'},
        {key:'个体户', value: '个体户'}
      ],
      businessTypeList: [
        {key:'修理厂', value: '修理厂'},
        {key:'检测站', value: '检测站'}
      ],
      formMode: 'create',
      beforeData: null,
      form: {
        name: '',
        typeName: '',
        subTitle: '',
        isMain: true,
        belong: '',
        openingHours: '',
        servicePhone: '',
        score: 100,
        insScores: [],
        canAppointment: true,
        orderCount: 0,
        address: '',
        addressPosition: null,
        content: ''
      },
      rules: {
        name: [
          { required: true, message: '请填写', trigger: 'blur' },
        ],
        typeName: [
          { required: true, message: '请填选择', trigger: 'change' },
        ],
        businessType: [
          { required: true, message: '请填选择', trigger: 'change' },
        ],
        belong: [
          { required: true, message: '请填选择', trigger: 'change' },
        ],
        openingHours: [
          { required: true, message: '请填写', trigger: 'blur' },
        ],
        servicePhone: [
          { required: true, message: '请填写', trigger: 'blur' },
        ],
        address: [{ required: true, message: '请填写', trigger: 'blur' },],
        addressPosition: [{ required: true, message: '请填选择', trigger: 'change' }]
      },
      insuranceBaseList: [],
      files: [],
      beforeFiles: [],
      previewVisible: false,
      previewImage: '',
      map: {
        center: {lng: 0, lat: 0},
        zoom: 3
      },
      BMap: null,
      belongList: [],
      fetchingBelong: false,
      editerOptions: {
        modules: {
          toolbar: [
            ["bold", "italic", "underline", "strike"], //加粗，斜体，下划线，删除线
            ["blockquote", "code-block"], //引用，代码块
            [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
            [{ list: "ordered" }, { list: "bullet" }], //列表
            [{ script: "sub" }, { script: "super" }], // 上下标
            [{ indent: "-1" }, { indent: "+1" }], // 缩进
            [{ direction: "rtl" }], // 文本方向
            [{ size: ["small", false, "large", "huge"] }], // 字体大小
            [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
            [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
            [{ font: [] }], //字体
            [{ align: [] }], //对齐方式
            ["link"],
            ["clean"], //清除字体样式
            ["image"], //上传图片、上传视频
          ],
        },
        theme: "snow",
      },
    }
  },
  methods: {
    mapReaderhandler({BMap, map}) {
      const _this = this
      this.BMap = BMap;
      this.map = map;
      map.addEventListener('click', function (e) {
        _this.form.addressPosition = e.point
      });
    },
    async GetInsuranceBaseList() {
      const apiRes = await this.$axios.get(`${Utils}/InsuranceBaseList`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        return;
      }
      this.insuranceBaseList = apiRes.data
    },
    searchAddressPosition(value) {
      if (this.BMap === null) {
        this.$message.error('地图查询未初始化')
        return
      }
      const mapGeocoder = new this.BMap.Geocoder();
      const _this = this
      mapGeocoder.getPoint(value, function (point) {
        if (point) {
          _this.form.addressPosition = { lng: point.lng, lat: point.lat }
        }
      })
    },
    addInsSorces() {
      this.form.insScores.push({
        name: '',
        value: 0,
        key: Date.now(),
      });
    },
    removeInsScores(item) {
      let index = this.form.insScores.indexOf(item);
      if (index !== -1) {
        this.form.insScores.splice(index, 1);
      }
    },
    async getDetail (uuid) {
      const apiRes = await this.$axios.get(`${BusinessShop}/Detail?uuid=${uuid}`)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        this.$message.error(apiRes.message);
        this.sending = false;
        return null;
      }
      this.beforeData = apiRes.data
      const copyData = JSON.parse(JSON.stringify(apiRes.data))
      const form = {
        name: copyData.name,
        typeName: copyData.typeName,
        businessType: copyData.businessType,
        subTitle: copyData.subTitle,
        isMain: copyData.isMain,
        belong: copyData.belong,
        insScores: copyData.insScores,
        openingHours: copyData.openingHours,
        servicePhone: copyData.servicePhone,
        score: copyData.score,
        canAppointment: copyData.canAppointment,
        orderCount: copyData.orderCount,
        address: copyData.address,
        addressPosition: copyData.addressPosition,
        content: copyData.content
      }
      this.$set(this, 'form', form)
      if (copyData.shopImages) {
        const beforeFiles = []
        let i = 0
        for (const it of copyData.shopImages) {
          beforeFiles.push({
            uid: `key_${i}`,
            name: `接收单${i}`,
            status: 'done',
            url: `${this.$appBaseUrl}${it}`
          })
          i++
        }
        this.$set(this, 'beforeFiles', beforeFiles)
      }
    },
    async handleSearchBelong(value) {
      if (!value || value.length < 2) return
      this.belongList = []
      this.fetchingBelong = true
      const res = await this.$axios
        .post(`${BusinessShop}/Fetch`, {
          name: value,
        })
        .catch(() => (this.fetchingBelong = false));
      if (!res.status) {
          this.$message.error(res.message);
          return;
        }
      this.belongList = res.data
    },
    // 图片上传处理
    handleRemoveImage(file) {
      const index = this.files.indexOf(file);
      const newFileList = this.files.slice();
      newFileList.splice(index, 1);
      this.files = newFileList;
    },
    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleCancelPreview() {
      this.previewVisible = false;
    },
    beforeUploadImage(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      if (!isJPG) {
        this.$message.error('只能上传JPG或PNG图片')
        return
      }
      return false
    },
    changeUploadImage({ fileList }) {
      this.files = fileList
    },
    handleRemoveBeforeImage() {
      this.$message.error('已上传文件无法删除，请通过重新上传文件覆盖原有上传文件')
      return false
    },
    async UploadOrderImages(uuid) {
      if (this.files.length < 1) return
      const formData = new FormData();
      for (const it of this.files) {
        formData.append('imageFiles', it.originFileObj, it.originFileObj.name)
      }
      const apiRes = await this.$axios.post(`${BusinessShop}/UploadImages?uuid=${uuid}`, formData)
        .catch(e => {
          return {
            status: false,
            message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
          }
        })
      if (!apiRes.status) {
        throw new Error(apiRes.message)
      }
      return
    },
    onSubmit() {
      this.$refs.ruleForm.validate(async valid => {
        if (!valid) {
          this.$message.error('请检查填写内容')
          return false
        }
        
        // 商家介绍
        if (!this.form.content) {
          this.$message.error('请填写商家介绍')
          return false
        }
        const postData = JSON.parse(JSON.stringify(this.form))
        let url = ''
        if (this.formMode === 'create') {
          // 图片检查
          if (this.files.length < 1) {
            this.$message.error('请上传门店图片')
            return false
          }
          url = `${BusinessShop}/Create`
        } else {
          url = `${BusinessShop}/Edit?uuid=${this.beforeData.uuid}`
        }
        this.modalLoadingMessage = '正在处理数据'
        this.modalLoading = true
        const res = await this.$axios.post(url, postData)
          .catch(e => {
            return {
              status: false,
              message: e.message.indexOf('401') > -1 ? '登录超时' : e.message
            }
          })
        if (!res.status) {
          this.modalLoading = false
          this.$message.error(res.message)
          return
        }
        this.modalLoadingMessage = '正在处理图片'
        await this.UploadOrderImages(res.data.uuid)
          .catch(e => {
            this.modalLoading = false
            this.$message.error(e.message)
          })
        this.modalLoading = false
        this.$router.replace({ name: 'BusinessShopTableList' }).catch(err => err)
      })
    }
  }
}
</script>

<style>
.map {
  width: 100%;
  height: 350px;
}
.dynamic-delete-button {
  cursor: pointer;
  position: relative;
  padding-left: 0px;
  top: 4px;
  font-size: 24px;
  color: #999;
  transition: all 0.3s;
}
.dynamic-delete-button:hover {
  color: #777;
}
.dynamic-delete-button[disabled] {
  cursor: not-allowed;
  opacity: 0.5;
}
</style>